.elm-datetimepicker-duration--picker-container {
    background-color: #fff;
    position: absolute;
    color: #22292f;
    font-size: .875rem;
    padding: .75rem;
    border-radius: 5px;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.15)
}

.elm-datetimepicker-duration--picker-header-chevrons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.elm-datetimepicker-duration--picker-header-chevron {
    cursor: pointer;
}

.elm-datetimepicker-duration--picker-header-chevron:hover {
    color: #38c172;
}

.elm-datetimepicker-duration--calendars-container {
    display: flex;
    margin-bottom: 1rem;
}

.elm-datetimepicker-duration--calendar {
    display: inline-flex;
}

.elm-datetimepicker-duration--calendar-spacer {
    display: inline-flex;
    width: 2rem;
}

.elm-datetimepicker-duration--calendar-header {
    padding: .25rem 0 0 0;
    font-weight: bold;
}

.elm-datetimepicker-duration--calendar-header-row {
    display: flex;
    justify-content: center;
}

.elm-datetimepicker-duration--calendar-header-text {
    display: inline-flex;
    align-items: center;
}

.elm-datetimepicker-duration--calendar-header-chevron.elm-datetimepicker-duration--hidden {
    visibility: hidden;
}

.elm-datetimepicker-duration--calendar-header-week {
    display: flex;
    color: #a0aec0;
    font-weight: normal;
    padding: .5rem 0 .25rem 0
}

.elm-datetimepicker-duration--calendar-header-day {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
}

.elm-datetimepicker-duration--calendar-month {
    height: 180px;
    width: 210px;
}

.elm-datetimepicker-duration--calendar-week {
    display: flex;
}

.elm-datetimepicker-duration--calendar-day {
    height: 30px;
    width: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: .75rem;
    background-color: #fff;
    color: #22292f;
    cursor: pointer;
    border: none;
    outline: 0;
}

.elm-datetimepicker-duration--calendar-day:hover {
    background-color: #a0aec0;
}

.elm-datetimepicker-duration--calendar-day.elm-datetimepicker-duration--hidden {
    visibility: hidden;
}

.elm-datetimepicker-duration--calendar-day.elm-datetimepicker-duration--disabled {
    color: #a0aec0;
    cursor: default;
    background-color: #fff;
}

.elm-datetimepicker-duration--calendar-day.elm-datetimepicker-duration--picked {
    background-color: #3490dc;
    color: #fff;
}

.elm-datetimepicker-duration--calendar-day.elm-datetimepicker-duration--today {
    color: #3490dc;
}

.elm-datetimepicker-duration--calendar-day.elm-datetimepicker-duration--between {
    background-color: #f1f5f8;
}

.elm-datetimepicker-duration--calendar-day.elm-datetimepicker-duration--today-between {
    background-color: #f1f5f8;
    color: #3490dc;
}

.elm-datetimepicker-duration--footer-container {
    display: flex;
}

.elm-datetimepicker-duration--footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-top: 1px solid #a0aec0;
    padding: .25rem .75rem .25rem .75rem;
}

.elm-datetimepicker-duration--time-pickers-container {
    width: 100%;
}

.elm-datetimepicker-duration--time-picker-toggle {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.elm-datetimepicker-duration--time-picker-information-container {
    align-items: center;
    width: 100%;
}

.elm-datetimepicker-duration--time-picker-container {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 35%;
}

.elm-datetimepicker-duration--date-display-container-no-pickers {
    display: inline-flex;
    justify-content: center;
    width: 100%;
}

.elm-datetimepicker-duration--date-display-container {
    display: inline-flex;
    justify-content: flex-end;
    width: 65%;
}

.elm-datetimepicker-duration--time-picker {
    display: flex;
    justify-content: space-between;
    padding: .25rem 0 .25rem 0
}

.elm-datetimepicker-duration--select-container {
    display: inline-flex;
}

.elm-datetimepicker-duration--select {
    display: inline-flex;
}

.elm-datetimepicker-duration--selection-time {
    margin-left: 5px;
}

.elm-datetimepicker-duration--selection-time-danger {
    margin-left: 5px;
    color: red;
}

.elm-datetimepicker-duration--select-spacer {
    display: inline-flex;
    padding: 0 .25rem 0 .25rem;
}